import StarIcon from '@mui/icons-material/Star';
import type {SxProps} from '@mui/material';
import {useTheme, Box, Paper} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef} from 'react';
import TagManager from 'react-gtm-module';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import StarReviewsImage from 'src/assets/5-star-reviews-logo.jpg';

type TestimonialOption = {
    text : string;
    author : string;
};

const testimonialOptions : TestimonialOption[] = [
    {
        // eslint-disable-next-line max-len
        text: 'The staff at Renewal by Andersen have made this the most comfortable and enjoyable purchase we have ever made. They were professional and everyone was friendly. I especially like all the follow-through from start to finish. They made us feel as if they really care, and they do! Can’t wait to have our other windows replaced! Call them—you won’t be disappointed!',
        author: 'Sharon V.',
    },
    {
        // eslint-disable-next-line max-len
        text: 'The salesman, measurer, and installation crew were great. Everyone was courteous and competent. We’re very appreciative of their efforts and were so satisfied with the front windows that we decided to do the back of the house and requested the same crew.',
        author: 'Margaret T.',
    },
    {
        // eslint-disable-next-line max-len
        text: 'We are delighted with the quality of the windows and the installation. They definitely add value to our home and save on energy bills to boot. I would highly recommend these windows to anyone.',
        author: 'William & Dorothy S.',
    },
    {
        // eslint-disable-next-line max-len
        text: 'Renewal by Andersen helped me choose the perfect door for my project, stayed within my budget, and sent professionals to work with me every step of the way. I loved the phone calls to schedule the work and introduce the great guy who installed it. Thanks for all your help!',
        author: 'Maggie W.',
    },
    {
        // eslint-disable-next-line max-len
        text: 'We’ve never before experienced such a superior sales experience, installation, or clean-up in any project we’ve done, period. We are thrilled with the beauty and functionality of our new windows!',
        author: 'Jan D.',
    },
    {
        // eslint-disable-next-line max-len
        text: 'In a single day I had five exterior doors replaced, including my patio slider, and all look amazing. They recommended and performed several improvements I had not even considered, which made me happy. They are a little pricier for a reason, the materials are of incredible quality and service at all phases was top of class. Could not recommend enough.',
        author: 'Kevin C.',
    },
    {
        // eslint-disable-next-line max-len
        text: 'I appreciated the honest, informative and no pressure sales approach during the initial estimate. Everyone I encountered at Renewal by Andersen was courteous and professional, great teamwork!',
        author: 'Leticia L.',
    },
    {
        // eslint-disable-next-line max-len
        text: 'We had other companies come out to our home prior to Renewal by Andersen. None of them possessed the professionalism and knowledge of Renewal by Andersen. This company hires people who are trustworthy and truly care about people! Something you don’t find in a company every day!',
        author: 'Bill D.',
    },
    {
        // eslint-disable-next-line max-len
        text: 'Great service across the board! The quotes were spot on and I was really impressed with the product. The windows are AMAZING! We have already cut our electric bill by $100. Renewal by Andersen is top of the line in my book!',
        author: 'Sierra S.',
    },
    {
        // eslint-disable-next-line max-len
        text: 'This was the easiest remodel I have ever done because Renewal by Andersen took care of all my questions and coordinated all the details. Everyone on their team was knowledgeable, caring, respectful and punctual! Beautiful finished product!',
        author: 'Nina M.',
    },
];

const TestimonialCard = ({sx, testimonial} : {sx ?: SxProps; testimonial : TestimonialOption}) : React.ReactElement => {
    return <Paper sx={{
        textAlign: 'center',
        border: '1px solid #001722',
        backgroundColor: '#f7f6f6',
        padding: '2.5rem',
        height: '22.25rem',
        ml: 5,
        overflow: 'scroll',
        ...sx,
    }}>
        <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
        <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
        <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
        <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
        <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
        <Typography variant={'h5'}>{testimonial.text}</Typography>
        <Typography sx={{mt: 2, fontSize: '16px', fontWeight: '500'}}>
            {'- ' + testimonial.author}
        </Typography>
    </Paper>;
};

const Testimonials = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const isMid = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'testimonials',
                    action: 'scroll',
                },
            });
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'testimonials'});
        }
    }, [isSectionVisible]);

    const fourRandomTestimonials = testimonialOptions.sort(() => 0.5 - Math.random()).slice(0, 4);

    return <>
        <Grid container xs={12} justifyContent={'center'}>
            <Box
                component={'img'}
                alt="Renewal by Anderson"
                src={StarReviewsImage}
                maxWidth={isMobile ? '15rem' : '22.5rem'}
                mb={'-50px'}
                ref={ref}
            />
        </Grid>
        <Grid container xs={12} spacing={5} mb={5}>
            {fourRandomTestimonials.map((testimonial, index) => <Grid xs={12} md={6} key={index}>
                <TestimonialCard
                    testimonial={testimonial}
                    sx={{
                        '>h5': {fontSize: isMobile ? '1.1em' : (isMid ? '1.2em' : '1.4em')},
                        padding: isMobile ? '2rem' : '2.5rem',
                    }}
                />
            </Grid>)}
        </Grid>
    </>;
};

export default Testimonials;
