import {PhoneEnabled} from '@mui/icons-material';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import TagManager from 'react-gtm-module';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import {useLsrcPhoneNumberQuery} from '@/queries/lsrc';
import type {LeadToken} from '@/types/lead-token';

const PhoneButtonDisplay = ({leadToken, sourceId} : {leadToken : LeadToken; sourceId : string}) : JSX.Element => {
    const phoneNumberQuery = useLsrcPhoneNumberQuery(sourceId);
    const eventMutation = useCreateLeadTokenEventMutation();

    const handleClick = async () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'phone_button',
                action: 'click',
            },
        });
        await eventMutation.mutateAsync({leadTokenId: leadToken.id, type: 'click', name: 'phone_button'});
    };

    return phoneNumberQuery.data
        ? <>
            <a
                style={{textDecoration: 'none', color: 'inherit'}}
                href={'tel:' + phoneNumberQuery.data}
                onClick={handleClick}
            >
                <Box
                    color="#000"
                    bgcolor={'#f9ac4d'}
                    borderRadius={6}
                    px={4}
                    py={1.5}
                    display="flex"
                    alignItems="center"
                >
                    <PhoneEnabled sx={{transform: 'scaleX(-1)'}}/>&nbsp;
                    <Typography variant={'body1'} display="inline" sx={{fontWeight: '700'}}>
                        CALL NOW {phoneNumberQuery.data}
                    </Typography>
                </Box>
            </a>
        </>
        : <></>;
};

const PhoneButton = ({leadToken} : {leadToken : LeadToken}) : JSX.Element => {
    if (!leadToken.sourceId) {
        return <></>;
    }

    return <PhoneButtonDisplay leadToken={leadToken} sourceId={leadToken.sourceId}/>;
};

export default PhoneButton;
