type Quiz = {
    steps : QuizStepType[];
};
export type QuizStepType = {
    stepNumber : number;
    stepKey : 'services' | 'age' | 'problems' | 'features';
    questionText : string;
    multiselect : boolean;
    options :
    QuizOption[];
};
export type QuizOption = {
    value : string;
    displayText : string;
    icon ?: string;
};
export type QuizAnswers = {
    services ?: string;
    age ?: string;
    problems ?: [];
    features ?: [];
};
export const quizJson : Quiz = {
    steps: [
        {
            stepNumber: 1,
            stepKey: 'services',
            questionText: 'What replacement services are you looking for?',
            multiselect: false,
            options: [
                {
                    value: 'window',
                    displayText: 'Window',
                    icon: '',
                },
                {
                    value: 'door',
                    displayText: 'Door',
                    icon: '',
                },
                {
                    value: 'both',
                    displayText: 'Both',
                    icon: '',
                },
            ],
        },
        {
            stepNumber: 2,
            stepKey: 'problems',
            questionText: 'Why do you need to replace your windows and doors?',
            multiselect: true,
            options: [
                {
                    value: 'style',
                    displayText: 'Outdated Style',
                },
                {
                    value: 'operate',
                    displayText: 'Hard to operate',
                },
                {
                    value: 'draft',
                    displayText: 'Drafts or leaks',
                },
                {
                    value: 'damage',
                    displayText: 'Cloudy or Moldy',
                },
                {
                    value: 'damage2',
                    displayText: 'Peeling or Cracking',
                },
                {
                    value: 'energy',
                    displayText: 'High energy cost',
                },
            ],
        },
        {
            stepNumber: 3,
            stepKey: 'features',
            questionText: 'What window and door features are you looking for?',
            multiselect: true,
            options: [
                {
                    value: 'heat',
                    displayText: 'Blocks heat and drafts',
                },
                {
                    value: 'operation',
                    displayText: 'Ease of operation',
                },
                {
                    value: 'warranty',
                    displayText: 'Warranty policy',
                },
                {
                    value: 'light',
                    displayText: 'Natural light',
                },
                {
                    value: 'energy',
                    displayText: 'Energy efficiency',
                },
            ],
        },
    ],
};
