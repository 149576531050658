import StarIcon from '@mui/icons-material/Star';
import {Button, Stack, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef} from 'react';
import {HashLink} from 'react-router-hash-link';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import {useLsrcPhoneNumberQuery} from '@/queries/lsrc';
import type {LeadToken} from '@/types/lead-token';

const HeroSection = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const phoneNumberQuery = useLsrcPhoneNumberQuery(leadToken.sourceId);

    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'hero_section'});
        }
    }, [isSectionVisible]);

    return <Grid container xs={12} sx={{backgroundColor: '#fbfaf7'}}>
        <Grid xs={12} md={6} sx={{pt: 2}}>
            <Stack alignItems={'center'} textAlign={'center'}>
                <Typography variant={'h3'} px={5} sx={{fontSize: mobile ? '2em' : '3em'}} ref={ref}>
                    Save Now on New Replacement Windows and Doors
                </Typography>
                <Typography sx={{
                    mt: 2,
                    fontSize: '1.5em',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    textDecoration: 'underline',
                }}>
                    <a href={'tel:' + (phoneNumberQuery.data ?? '18005011400')} style={{color: '#001722'}}>
                        Call {phoneNumberQuery.data ?? '1-800-501-1400'}
                    </a>
                </Typography>
                <HashLink smooth to={`/${leadToken.id}#quiz-container`} scroll={el => {
                    el.scrollIntoView({behavior: 'smooth', block: 'center'});
                }}>
                    <Button sx={{
                        color: '#000',
                        bgcolor: '#f9ac4d',
                        borderRadius: 6,
                        px: 4,
                        py: 1.5,
                        '&.MuiButtonBase-root:hover': {
                            bgcolor: '#f9ac4d',
                        },
                        m: 1,
                    }}>
                        Start Quiz to See Your Local Offer
                    </Button>
                </HashLink>
                <Grid sx={{mt: 4, mb: 3}}>
                    <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
                    <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
                    <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
                    <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
                    <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
                    <Typography variant={'body1'} sx={{
                        textTransform: 'uppercase',
                        color: '#50565a',
                        fontWeight: 700,
                    }}>Join the More Than 500,000 that Recommend Renewal by Andersen Corporation*</Typography>
                </Grid>
            </Stack>
        </Grid>
        {!mobile && <Grid xs={12} md={6}>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} textAlign={'center'}>
                <iframe
                    title="Stunning"
                    src="https://player.vimeo.com/video/1015851499?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    width="940"
                    height="424"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                />
            </Grid>
        </Grid>}
    </Grid>;
};

export default HeroSection;
