import {Box, Divider, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Link, Outlet, useLocation} from 'react-router-dom';
import LogoImage from '/src/assets/LOGO-RbA-Horizontal.jpg';
import Footer from '@/components/Footer';
import PhoneButton from '@/components/PhoneButton';
import {useLeadTokenContext} from '@/contexts/LeadTokenContext';

const Layout = () : JSX.Element => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {leadToken} = useLeadTokenContext();
    const {pathname} = useLocation();

    const displayFullHeader = !pathname.startsWith('/confirmation');

    return (
        <>
            {displayFullHeader && <Box style={{position: 'sticky', top: '0px', zIndex: 1, backgroundColor: '#fff'}}>
                <Grid container justifyContent={'center'}>
                    <Grid
                        xs={12}
                        display="flex"
                        flexDirection={mobile ? 'column' : 'row'}
                        alignItems={'center'}
                        sx={{backgroundColor: '#001722'}}
                    >
                        <Grid flexGrow={1}></Grid>
                        <Grid flexGrow={0} padding={2} sx={{textAlign: 'center'}}>
                            <Typography sx={{
                                color: 'white',
                                textTransform: 'uppercase',
                                letterSpacing: '2px',
                                lineHeight: 1.5,
                                fontWeight: '900',
                                fontSize: '1rem',
                                textUnderlineOffset: '2px',
                            }}>
                            Call us before 2PM for <u>same day appointments!</u>
                            </Typography>
                        </Grid>
                        <Grid flexGrow={1}></Grid>
                    </Grid>
                    <Grid
                        xs={12} md={10}
                        display="flex"
                        flexDirection={mobile ? 'column' : 'row'}
                        alignItems={'center'}
                    >
                        <Grid flexGrow={0}>
                            {leadToken && <Link to={`/${leadToken.id}`}>
                                <Box
                                    component={'img'}
                                    alt="Renewal by Anderson"
                                    src={LogoImage}
                                    sx={{height: '75px', width: '200px'}}
                                />
                            </Link>}
                        </Grid>
                        <Grid flexGrow={1}></Grid>
                        <Grid display="flex" flexGrow={1} justifyContent="right" alignItems={'center'}>
                            {leadToken && <PhoneButton leadToken={leadToken}/>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <Grid xs={12}><Divider sx={{backgroundColor: 'primary.main', height: '5px'}}/></Grid>
                </Grid>
            </Box>}
            <main>
                <Outlet/>
            </main>
            {leadToken && <Footer leadToken={leadToken}/>}
        </>
    );
};

export default Layout;
