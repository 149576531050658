import {Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useEffect, useRef} from 'react';
import TagManager from 'react-gtm-module';
import DiscoverCarousel from '@/components/DiscoverCarousel';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';

const DiscoverBeauty = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const eventMutation = useCreateLeadTokenEventMutation();

    const discoverBeautyRef = useRef<HTMLElement>(null);
    const isDiscoverBeautyVisible = useOnScreen(discoverBeautyRef);

    useEffect(() => {
        if (isDiscoverBeautyVisible) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'discover_beauty',
                    action: 'scroll',
                },
            });
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'discover_beauty'});
        }
    }, [isDiscoverBeautyVisible]);

    return (
        <Stack
            sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                backgroundColor: '#f8f9fa',
            }}
        >
            <Typography variant={'h3'} sx={{mt: 5, p: 5}} ref={discoverBeautyRef}>
                Discover Beauty That Fits Your Budget
            </Typography>
            <DiscoverCarousel/>
        </Stack>
    );
};

export default DiscoverBeauty;
